import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

// FontAwesome
import '@/assets/icons/font-awesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Nl2br from 'vue3-nl2br'
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

import auth from '@/auth';
auth.init().then(() => {
  console.log('auth init')
  const app = createApp(App)
  app.use(store)
  app.use(router)
  app.use(CoreuiVue)
  app.use(VueAxios, axios)
  app.use(VCalendar, {})
  app.provide('icons', icons)
  app.component('CIcon', CIcon)
  app.component('nl2br', Nl2br)
  app.component('font-awesome-icon', FontAwesomeIcon)

  app.mount('#app')
});


