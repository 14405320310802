import {
  cibApple,
  cilMoodVeryGood,
  cilApplications,
  cilMoon,
  cilSun,
  cilPlus,
  cilMinus,
  cilTrash,
  cilPen,
  cilX,
  cilBuilding,
  cilLinkAlt,
  cilMenu,
  cilCaretLeft,
  cilCaretRight,
  cilBolt,
  cilSave,
  cilBold,
  cilItalic,
  cilStrikethrough,
  cilParagraph,
  cilHeader,
  cilListNumbered,
  cilList,
  cilActionUndo,
  cilActionRedo,
  cilImage,
  cilInbox,
  cilCalendar,
  cilMediaPlay,
  cilHistory,
  cilLowVision,
  cilCloudUpload,
  cilUser,
  cilLockLocked,
  cibGoogle
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {
    cibApple,
    cilMoodVeryGood,
    cilApplications,
    cilMoon,
    cilSun,
    cilPlus,
    cilMinus,
    cilTrash,
    cilPen,
    cilX,
    cilBuilding,
    cilLinkAlt,
    cilMenu,
    cilCaretLeft,
    cilCaretRight,
    cilBolt,
    cilSave,
    cilBold,
    cilItalic,
    cilStrikethrough,
    cilParagraph,
    cilHeader,
    cilListNumbered,
    cilList,
    cilActionUndo,
    cilActionRedo,
    cilImage,
    cilInbox,
    cilCalendar,
    cilMediaPlay,
    cilHistory,
    cilLowVision,
    cilCloudUpload,
    cilUser,
    cilLockLocked,
    cibGoogle
  }
)
