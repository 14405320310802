export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-applications',
  },
  {
    component: 'CNavTitle',
    name: 'Stranke',
  },
  {
    component: 'CNavItem',
    name: 'Pregled accountov',
    to: '/clients',
    icon: 'cil-building',
  },
  {
    component: 'CNavItem',
    name: 'Brainstormingi',
    to: '/brainstormings',
    icon: 'cil-bolt',
  },
  {
    component: 'CNavItem',
    name: 'Scenariji',
    to: '/scenes',
    icon: 'cil-image',
  },
  {
    component: 'CNavItem',
    name: 'Plani objav',
    to: '/posts',
    icon: 'cil-calendar',
  },
  {
    component: 'CNavTitle',
    name: 'Baze podatkov',
  },
  {
    component: 'CNavItem',
    name: 'Vplivneži',
    to: '/influencers',
    icon: 'cil-mood-very-good',
  },
  {
    component: 'CNavItem',
    name: 'Znamke',
    to: '/brands',
    icon: 'cib-apple',
  }
]
