import { createStore } from 'vuex'
import auth from '@/auth';

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
    currentClient: 0,
    user: null
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    updateCurrentClient(state,clientId) {
      state.currentClient = clientId;
    },
    updateUser(state, user) {
      state.user = user;
    },
    logoutUser(state) {
      state.user = null;
    }
  },
  getters: {
    user: state => state.user,
    isLogged: state => (state.user !== null)
  },
  actions: {
    setCurrentUser: ({ commit }) => {
      commit('updateUser',auth.user())
    }
  },
  modules: {},
})
